import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { ITopic } from "../data/topic";

const API_URL = "topics";

class TopicService {
  public static getTopics(searchTopicFilter): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/filter", searchTopicFilter);
  }

  public static getTopicById(id): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/" + id);
  }

  public static createTopic(topic: ITopic): Promise<AxiosResponse> {
    return ApiService.post(API_URL, topic);
  }

  public static updateTopic(topic: ITopic): Promise<AxiosResponse> {
    return ApiService.put(API_URL, topic);
  }

  public static deleteTopic(id): Promise<AxiosResponse> {
    return ApiService.delete(API_URL + "/" + id);
  }
}

export default TopicService;
