
import { ITopic } from "@/core/data/topic";
import TopicService from "@/core/services/TopicService";
import ActivityService from "@/core/services/ActivityService";
import { defineComponent, watch, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { IActivity } from "@/core/data/activity";
import CreateTopicModal from "@/views/studio-lab/components/modals/CreateTopicModal.vue";
import Swal from "sweetalert2";
import { useAuthStore } from "@/store/useAuthStore";
import UserRoleService from "@/core/services/UserRoleService";
export default defineComponent({
  components: { CreateTopicModal },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const authStore = useAuthStore();
    const currentTopic = ref<ITopic>();
    const activityModalRef = ref();
    const topicModalRef = ref();
    const activities = ref<Array<IActivity>>([]);
    const isTeacher = ref<boolean>(false);
    const activityAttachmentbaseURL = ref(
      process.env.VUE_APP_API_URL + "activities/attachments/"
    );

    const initTopic = () => {
      TopicService.getTopicById(route.params.id).then((response) => {
        currentTopic.value = response.data;
      });
    };

    const initActivities = () => {
      ActivityService.getActivityByTopicId(route.params.id).then((response) => {
        activities.value = response.data;
      });
    };

    const getTeacher = () => {
      UserRoleService.isTeacher().then((response) => {
        isTeacher.value = response.data;
      });
    };

    const createActivity = () => {
      activityModalRef.value.currentTopic = currentTopic.value;
      activityModalRef.value.showModal();
    };

    const editTopic = () => {
      topicModalRef.value.editTopic(currentTopic.value);
      topicModalRef.value.showModal();
    };

    const deleteTopic = () => {
      Swal.fire({
        title: `You want to delete this topic (${currentTopic.value!.title})?`,
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#F64E60",
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
      }).then((result) => {
        if (result.value) {
          TopicService.deleteTopic(currentTopic.value!.id)
            .then((response) => {
              Swal.fire({
                text: "Delete topic complete",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              authStore.setTopicDataChange(true);
              router.back();
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    };

    const deleteActivity = (activity) => {
      Swal.fire({
        title: `You want to delete this activity (${activity.title})?`,
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#F64E60",
        confirmButtonText: "confirm",
        cancelButtonText: "cancel",
      }).then((result) => {
        if (result.value) {
          ActivityService.deleteActivity(activity.id)
            .then((response) => {
              Swal.fire({
                text: "Delete activity complete",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              authStore.setTopicDataChange(true);
              initTopic();
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    };

    watch(
      () => route.params.id,
      (state, prevState) => {
        initTopic();
        initActivities();
      }
    );

    onMounted(() => {
      initTopic();
      initActivities();
      getTeacher();
    });

    return {
      currentTopic,
      createActivity,
      initActivities,
      activities,
      topicModalRef,
      activityModalRef,
      editTopic,
      initTopic,
      deleteTopic,
      deleteActivity,
      isTeacher,
      activityAttachmentbaseURL,
    };
  },
});
