
import { computed, defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import { ITopic } from "@/core/data/topic";
import { hideModal } from "@/core/helpers/dom";
import TopicService from "@/core/services/TopicService";
import TopicStatus from "@/core/constants/TopicStatus";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuthStore } from "@/store/useAuthStore";
export default defineComponent({
  name: "new-topic-modal",
  emit: ["onSaveBtnClick"],
  setup(props, { emit }) {
    let thisModalObj;
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const authStore = useAuthStore();
    const newTopicModalRef = ref<null | HTMLElement>(null);
    const topicStatuses = Object.values(TopicStatus);
    const topicData = ref<ITopic>({
      id: null,
      title: "",
      description: "",
      status: TopicStatus.UP_COMING,
      activities: null,
      createdBy: null,
    });

    const rules = computed(() => {
      return {
        title: [
          {
            required: true,
            message: "Please input topic title.",
            trigger: "blur",
          },
          {
            max: 255,
            message: "Topic title must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Please input topic description.",
            trigger: "blur",
          },
          {
            max: 255,
            message: "Topic description must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select topic status.",
            trigger: "change",
          },
        ],
      };
    });

    onMounted(() => {
      thisModalObj = new Modal(newTopicModalRef.value);
      var topicModal = document.getElementById("kt_modal_new_topic");
      topicModal!.addEventListener("hidden.bs.modal", function () {
        resetModal();
      });
    });

    const resetModal = () => {
      topicData.value.id = null;
      topicData.value.title = "";
      topicData.value.description = "";
      topicData.value.status = "";
    };

    const editTopic = (topic) => {
      topicData.value.id = topic.id;
      topicData.value.title = topic.title;
      topicData.value.description = topic.description;
      topicData.value.status = topic.status;
    };

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          //new topic
          if (topicData.value.id == null) {
            TopicService.createTopic(topicData.value)
              .then((response) => {
                loading.value = false;
                emit("onSaveBtnClick");
                authStore.setTopicDataChange(true);
                hideModal(newTopicModalRef.value);
                Swal.fire({
                  text: "Save complete",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch(({ response }) => {
                loading.value = false;
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          } else {
            TopicService.updateTopic(topicData.value)
              .then((response) => {
                loading.value = false;
                emit("onSaveBtnClick");
                authStore.setTopicDataChange(true);
                hideModal(newTopicModalRef.value);
                Swal.fire({
                  text: "Save complete",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch(({ response }) => {
                loading.value = false;
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          }

          authStore.setTopicDataChange(true);
        } else {
          return false;
        }
      });
    };

    const showModal = () => {
      thisModalObj.show();
    };
    return {
      loading,
      formRef,
      newTopicModalRef,
      showModal,
      topicData,
      onSubmit,
      rules,
      editTopic,
      topicStatuses,
    };
  },
});
